<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>背书管理</el-breadcrumb-item>
			<el-breadcrumb-item>背书申请</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<!--步骤条-->
			<el-steps :active="activeStep"
			          process-status="wait"
			          finish-status="finish"
			          align-center
			          class="mt30">
				<el-step title="填写背书信息"></el-step>
				<el-step title="核对信息"></el-step>
				<el-step title="完成"></el-step>
			</el-steps>
			<EndorseApplyOne v-if="activeStep == 1"
											 @handleSubmit="handleSubmit"></EndorseApplyOne>
			<EndorseApplyTwo v-else-if="activeStep == 2"
											 :ticketId = 'ticketId'
											 @handleSubmit="handleSubmit"></EndorseApplyTwo>
			<Status v-else-if="activeStep == 3">
				<template v-slot:statusDescribe>
					<h3>背书登记申请成功</h3>
					<h3 class="operation-number">操作编号：<span>{{handlerNo}}</span></h3>
					<el-button type="primary" @click="handleCopy" style="margin-top: 20px">点击复制</el-button>
				</template>
				<template v-slot:statusBtn>
					<el-button type="primary" size="medium" @click="handleSuccess">完成</el-button>
				</template>
			</Status>
		</el-card>
	</div>
</template>

<script>
	/*填写背书信息*/
	import EndorseApplyOne from "./EndorseApplyOne";
	/*核对信息*/
	import EndorseApplyTwo from "./EndorseApplyTwo";
	/*完成*/
	import Status from "@/views/Ticket/childrenCpn/Status";
  export default {
    name: "EndorseApplyStep",
	  components: {
      EndorseApplyOne,
      EndorseApplyTwo,
      Status
	  },
	  data(){
      return {
        activeStep: 1,// 步骤条当前激活步骤
				text: '', //成功后的文本
				handlerNo: '', // 成功后的操作编号
				ticketId: '', // 票据主键 --- 列表页传递过来的
			}
	  },
	  methods: {
      // 监听保存
      handleSubmit({activeStep, ticketId, text, handlerNo}){
        this.activeStep = activeStep;
				this.ticketId = ticketId;
				this.text = text;
				this.handlerNo = handlerNo
      },
		  // 完成
      handleSuccess(){
        this.$router.push({name: 'endorseApplyList'})
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.handlerNo).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
	  }
  }
</script>

<style lang="less" scoped>
	.operation-number{
		margin-top: 10px;
		span{
			color: #C70009;
		}
	}
</style>