<template>
	<!--申请背书 - 核对信息-->
	<div>
		<!--背书信息-->
		<div class="title mt30">
			<h3>背书信息</h3>
		</div>
		<el-descriptions :column="2" class="mt20">
			<el-descriptions-item label="被背书人">{{baseInfo.compName}}</el-descriptions-item>
			<el-descriptions-item label="被背书人账号">{{baseInfo.acctNo}}</el-descriptions-item>
			<el-descriptions-item label="开户行">{{baseInfo.acctBankName}}</el-descriptions-item>
			<el-descriptions-item label="开户行行号">{{baseInfo.acctBankNo}}</el-descriptions-item>
			<el-descriptions-item label="背书金额（元）">{{baseInfo.packageSum}}</el-descriptions-item>
			<el-descriptions-item label="申请日期">{{baseInfo.applyDate}}</el-descriptions-item>
			<el-descriptions-item label="转让标记">{{baseInfo.transferFlag}}</el-descriptions-item>
			<el-descriptions-item label="备注">{{baseInfo.remark}}</el-descriptions-item>
		</el-descriptions>
		<!--母票基本信息-->
		<div class="title mt30">
			<h3>母票基本信息</h3>
			<el-button type="primary" @click="handleFaceView()">票面预览</el-button>
			<el-button type="primary" @click="handleFaceDownload()">票面下载</el-button>
		</div>
		<el-table :data="superInfoList" border class="mt20">
			<el-table-column prop="packageNo" label="票据包号"></el-table-column>
			<el-table-column prop="packageSum" label="票据包金额"></el-table-column>
			<el-table-column prop="acceptor" label="承兑人"></el-table-column>
			<el-table-column prop="expireDate" label="票据到期日"></el-table-column>
			<el-table-column prop="ticketRange" label="子票区间"></el-table-column>
		</el-table>
		<!--子票基本信息-->
		<template v-if="subInfoList !== null">
      <div class="title mt30">
        <h3>子票基本信息</h3>
      </div>
      <el-table :data="subInfoList" border class="mt20">
        <el-table-column prop="subTicketInfo" label="子票信息"></el-table-column>
        <el-table-column prop="possessor" label="票据归属"></el-table-column>
        <el-table-column prop="packageSum" label="子票包金额"></el-table-column>
        <el-table-column prop="transferFlag" label="转让标记"></el-table-column>
      </el-table>
    </template>
		<div class="btn">
			<el-button @click="handleBack">上一步</el-button>
			<el-button type="primary" @click="handleSave">确认提交</el-button>
		</div>
	</div>
</template>

<script>
  export default {
    name: "EndorseApplyTwo",
		props: ['ticketId'],
		mounted(){
			// 获取数据
    	this.getInfo()
		},
		data(){
    	return {
    		// 背书信息
				baseInfo: {
					pid: null, // 母票id
					subId: null, // 票据id
					compName: '', // 被背书人
					acctNo: '', // 被背书人账号
					acctBankName: '', //开户行
					acctBankNo: '', // 开户行行号
					packageSum: '', // 背书金额
					applyDate: '', // 申请日期
					transferFlag: '', // 转让标记
					remark: '', // 备注
				},
				superInfoList: [], // 母票基本信息
				subInfoList: [], // 子票基本信息
			}
		},
	  methods: {
    	// 获取数据
    	async getInfo(){
				const {data: res} = await this.$auth.get(`/cpiaoju-ticket/bs/manage/apply/check/${this.ticketId}`)
				if(res.code !== 200) return this.$message.error(res.msg);
				this.baseInfo = res.data.baseInfo;
				this.superInfoList = res.data.superInfoList;
				this.subInfoList = res.data.subInfoList
			},
			// 票面预览
			async handleFaceView(){
				let params = {
					paperBagNo: this.superInfoList[0].packageNo,
					section: this.superInfoList[0].ticketRange
				}
				const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceView', this.$qs.stringify(params), {responseType: 'blob'});
				if (res.data.type === "application/pdf"){
					const blob = res.data;
					let link = document.createElement('a');
					link.setAttribute('target', '_blank');
					link.href = window.URL.createObjectURL(blob);
					link.click()
					URL.revokeObjectURL(link.href);
				}else{
					return this.$message.error('暂无票面信息')
				}
			},
			// 票面下载
			async handleFaceDownload(){
				let params = {
					paperBagNo: this.superInfoList[0].packageNo,
					section: this.superInfoList[0].ticketRange
				}
				const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceDownload', this.$qs.stringify(params), {responseType: 'blob'});
				if (res.headers['content-disposition']){
					const blob = res.data;
					const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]); //这是下载的关键
					let link = document.createElement('a');
					let url = URL.createObjectURL(blob);
					link.href = url;
					link.download = fileName
					console.log(link)
					link.click()
					URL.revokeObjectURL(url)
				}else{
					return this.$message.error('暂无票面信息')
				}
			},
      // 返回
      handleBack(){
				this.$emit('handleSubmit', {activeStep: 1})
      },
      //下一步
      async handleSave(){
    		const {data: res} = await this.$auth.post('/cpiaoju-ticket/bs/manage/apply/submit',this.$qs.stringify({ticketId: this.ticketId}));
    		if(res.code !== 200) return this.$message.error(res.msg)
        this.$emit('handleSubmit', {activeStep: 3, text: res.data.text, handlerNo: res.data.handlerNo})
      }
	  }
  }
</script>

<style lang="less" scoped>
	.title{
		h3{
			display: inline-block;
			margin-right: 10px;
		}
	}
	.btn{
		text-align: center;
		margin-top: 50px;
		.el-button{
			min-width: 120px;
		}
	}
</style>