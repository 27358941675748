<template>
	<!--申请背书 - 填写背书信息-->
	<div>
		<!--票据基本信息-->
		<div class="title mt30">
			<h3>票据基本信息</h3>
			<el-button type="primary" @click="handleFaceView()">票面预览</el-button>
			<el-button type="primary" @click="handleFaceDownload()">票面下载</el-button>
		</div>
		<el-table :data="superInfoList" border class="mt20">
			<el-table-column prop="packageNo" label="票据包号"></el-table-column>
			<el-table-column prop="packageSum" label="票据包金额"></el-table-column>
			<el-table-column prop="acceptor" label="承兑人"></el-table-column>
			<el-table-column prop="expireDate" label="票据到期日"></el-table-column>
			<el-table-column prop="ticketRange" label="子票区间"></el-table-column>
		</el-table>
		<!--背书信息-->
		<div class="title mt30">
			<h3>背书信息</h3>
		</div>
		<el-form :model="endorseForm"
		         :rules="endorseFormRules"
		         ref="endorseFormRef"
		         label-width="140px"
		         class="endorse-form">
			<el-form-item label="被背书人：" prop="compName">
				<el-input v-model="endorseForm.compName" @input="getCompName"></el-input>
			</el-form-item>
			<el-form-item label="被背书人账号：" prop="acctNo">
				<el-select placeholder="请选择"
									 v-model="endorseForm.acctNo"
									 @change="getAcctNo">
					<el-option v-for="(item, index) in accountList"
										 :key="item"
										 :label="item"
										 :value="item"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="开户行：" prop="acctBankName">
				{{endorseForm.acctBankName}}
			</el-form-item>
			<el-form-item label="开户行行号：" prop="acctBankNo">
				{{endorseForm.acctBankNo}}
			</el-form-item>
			<el-form-item label="背书金额（元）：" prop="packageSum">
				<el-input v-model="endorseForm.packageSum"></el-input>
			</el-form-item>
			<el-form-item label="申请日期：" prop="applyDate">
				{{endorseForm.applyDate}}
			</el-form-item>
			<el-form-item label="转让标记：" prop="transferFlag">
				<el-select placeholder="请选择" v-model="endorseForm.transferFlag">
					<el-option label="可转让" value="EM00"></el-option>
					<el-option label="不可转让" value="EM01"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="备注：" prop="remark">
				<el-input v-model="endorseForm.remark"
				          type="textarea"
				          resize="none"></el-input>
			</el-form-item>
		</el-form>
		<div class="btn">
			<el-button @click="handleBack">返回</el-button>
			<el-button type="primary" @click="handleSave">下一步</el-button>
		</div>
	</div>
</template>

<script>
  export default {
    name: "EndorseApplyOne",
		mounted(){
			this.ticketId = this.$route.query.ticketId
			// 获取票据基本信息列表
			this.getBasicList();
		},
	  data(){
      return {
				ticketId: null, // 票据id --- 第一次
				// subId: null, // 票据id --- 不是第一次
				superInfoList: [], // 票据基本信息列表
				//背书信息
        endorseForm: {
					compName: '',//被背书人
					acctNo: '', //被背书人账号
					acctBankName: '', //开户行
					acctBankNo: '', //开户行行号
					packageSum: '', //背书金额（元）
					applyDate: '', //申请日期
					transferFlag: '', //转让标记(EM00 可转让 EM01 不可转让)
					remark: '', //备注
        },
	      // 背书信息验证规则
        endorseFormRules: {
					compName: [
	          { required: true, message: '请输入被背书人', trigger: 'blur' }
          ],
					acctNo: [
            { required: true, message: '请选择被背书人账号', trigger: 'change' }
          ],
					acctBankName: [
              { required: true, message: '开户行必填', trigger: 'blur' }
          ],
					acctBankNo: [
           { required: true, message: '开户行行号必填', trigger: 'blur' }
          ],
					packageSum: [
              { required: true, message: '请输入背书金额', trigger: 'blur' }
          ],
					applyDate: [
              { required: true, message: '申请日期必填', trigger: 'blur' }
          ],
					transferFlag: [
            { required: true, message: '请选择转让标记', trigger: 'change' }
          ]
        },
				accountList: [], // 被背书人账号
      }
	  },
	  methods: {
			// 获取票据基本信息列表
			async getBasicList(){
				let params = {
					ticketId: this.ticketId
				}
				const {data: res} = await this.$auth.post('/cpiaoju-ticket/bs/manage/apply', this.$qs.stringify(params));
				if (res.code !== 200) return this.$message.error(res.msg);
				this.superInfoList = res.data.superInfoList;
				this.endorseForm = {...res.data.baseInfo};
			},
			// 获取被背书人账号
			async getCompName(){
				const {data: res} = await this.$auth.get(`/cpiaoju-ticket/common/accounts/${this.endorseForm.compName}`)
				if(res.code !== 200) return this.$message.error(res.msg);
				this.accountList = res.data.accountList
			},
			// 根据背书人账号 获取 开户行和开户行行号
			async getAcctNo(value){
				const {data: res} = await this.$auth.get(`/cpiaoju-ticket/common/account/info/${value}`)
				if(res.code !== 200) return this.$message.error
				this.endorseForm.acctBankName = res.data.acctBankName;
				this.endorseForm.acctBankNo = res.data.acctBankNo
			},
			// 票面预览
			async handleFaceView(){
				let params = {
					paperBagNo: this.superInfoList[0].packageNo,
					section: this.superInfoList[0].ticketRange
				}
				const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceView', this.$qs.stringify(params), {responseType: 'blob'});
				if (res.data.type === "application/pdf"){
					const blob = res.data;
					let link = document.createElement('a');
					link.setAttribute('target', '_blank');
					link.href = window.URL.createObjectURL(blob);
					link.click()
					URL.revokeObjectURL(link.href);
				}else{
					return this.$message.error('暂无票面信息')
				}
			},
			// 票面下载
			async handleFaceDownload(){
				let params = {
					paperBagNo: this.superInfoList[0].packageNo,
					section: this.superInfoList[0].ticketRange
				}
				const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceDownload', this.$qs.stringify(params), {responseType: 'blob'});
				console.log(res)
				if (res.headers['content-disposition']){
					const blob = res.data;
					const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]); //这是下载的关键
					let link = document.createElement('a');
					let url = URL.createObjectURL(blob);
					link.href = url;
					link.download = fileName
					console.log(link)
					link.click()
					URL.revokeObjectURL(url)
				}else{
					return this.$message.error('暂无票面信息')
				}
			},
      // 返回
      handleBack(){
        this.$router.push({name: 'endorseApplyList'})
      },
      //下一步
      handleSave(){
				this.$refs.endorseFormRef.validate(async valid => {
          if (!valid) return this.$message.error(res.msg)
          if (Number(this.endorseForm.packageSum) > Number(this.superInfoList[0].packageSum)){
            this.$message.error('背书金额不能大于票据包金额');
            return false;
          }else{
            const {data: res} = await this.$auth.post('/cpiaoju-ticket/bs/manage/apply/save', this.$qs.stringify(this.endorseForm))
            console.log(res)
            if (res.code !== 200) return this.$message.error(res.msg);
            this.$emit('handleSubmit', {activeStep: 2, ticketId: this.ticketId})
          }
        })
      }
	  }
  }
</script>

<style lang="less" scoped>
	.title{
		h3{
			display: inline-block;
			margin-right: 10px;
		}
	}
	.endorse-form{
		width: 500px;
		margin-top: 20px;
		.el-select{
			width: 100%;
		}
	}
	.btn{
		text-align: center;
		margin-top: 50px;
		.el-button{
			min-width: 120px;
		}
	}
</style>